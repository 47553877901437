import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';

const UserExperience = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const [userExperiences, setUserExperiences] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFocused, setIsFocused] = useState('');
  const [errors, setErrors] = useState({});
  const [modalButtonGroup, setModalButtonGroup] = useState('createExperience');

  const [formData, setFormData] = useState({
    id: '',
    jobTitle: '',
    institutionName: '',
    location: '',
    fromDate: '',
    toDate: '',
    currentlyWorkHere: false,
  });

  const imageBaseUrl = `${network.serverip}/images/`;

  const handleExperienceCreate = () => {
    setModalVisible(true);
    setModalButtonGroup('createExperience');
    setFormData({
      id: '',
      jobTitle: '',
      institutionName: '',
      location: '',
      fromDate: '',
      toDate: '',
      currentlyWorkHere: false,
    });
  }

  const handleExperienceEdit = (data) => {
    setModalButtonGroup('editExperience');
    setModalVisible(true);
    setFormData({
      id: data?.id || '',
      jobTitle: data?.job_title || '',
      institutionName: data?.institution_name || '',
      location: data?.location || '',
      fromDate: data?.from_date || '',
      toDate: data?.currently_work_here ? '' : data?.to_date || '',
      currentlyWorkHere: data?.currently_work_here ? true : false,
    });
  }

  const handleClose = () => {
    setModalVisible(false);
    setFormData({
      id: '',
      jobTitle: '',
      institutionName: '',
      location: '',
      fromDate: '',
      toDate: '',
      currentlyWorkHere: false,
    });
    setErrors({});
    setIsFocused('');
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const fetchUserExperiences = async () => {

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    try {
      const response = await axios.get(`${network.serverip}/api/user-experience-list-web/${userId}`);

      if (response.data.success && isMountedRef.current) {

        const userExperienceData = response.data.data || [];

        const carbonNow = new Date();

        const sortedData = userExperienceData.sort((a, b) => {
          const dateA = new Date(a.to_date || carbonNow);
          const dateB = new Date(b.to_date || carbonNow);

          return dateB - dateA;
        });

        setUserExperiences(sortedData);
        console.log('This is the sorted fetched user experiences:', sortedData);

      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUserExperiences([]);
        }
        console.error('Failed to fetch user experiences');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUserExperiences([]);
      }
      console.error('Error fetching user experiences:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setModalVisible(false);
        setFormData({
          id: '',
          jobTitle: '',
          institutionName: '',
          location: '',
          fromDate: '',
          toDate: '',
          currentlyWorkHere: false,
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSaveAndAddAnother = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setFormData({
          id: '',
          jobTitle: '',
          institutionName: '',
          location: '',
          fromDate: '',
          toDate: '',
          currentlyWorkHere: false,
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async () => {

    console.log('This is the formData', formData);

    setErrors({});

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    let isValid = true;

    if (!formData.jobTitle) {
      setErrors(prevErrors => ({
        ...prevErrors,
        jobTitle: 'Please add job title'
      }));
      isValid = false;
    }

    if (!formData.institutionName) {
      setErrors(prevErrors => ({
        ...prevErrors,
        institutionName: 'Please add hospital name'
      }));
      isValid = false;
    }

    if (!formData.fromDate) {
      setErrors(prevErrors => ({
        ...prevErrors,
        fromDate: 'Please select joining date'
      }));
      isValid = false;
    }

    if (!formData.toDate && !formData.currentlyWorkHere) {
      setErrors(prevErrors => ({
        ...prevErrors,
        toDate: 'Please select last working date'
      }));
      isValid = false;
    }

    const fromDate = formData.fromDate ? new Date(formData.fromDate) : null;
    const carbonNow = new Date();
    let toDate = null;

    if (formData.currentlyWorkHere) {
      toDate = carbonNow || null;
    } else {
      toDate = formData.toDate ? new Date(formData.toDate) : null;
    }

    if (fromDate && toDate && fromDate > toDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        toDate: "The start date cannot be later than the completion date.",
        fromDate: "The start date cannot be later than the completion date.",
      }));
    }

    if (!isValid) {
      return { success: false };
    }

    try {

      formData.userId = userId;

      const response = await axios.post(
        `${network.serverip}/api/user-experience-${formData.id ? 'edit' : 'create'}-web`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success && isMountedRef.current) {
        console.log(`User experience uploaded successfully:`, response.data);
        fetchUserExperiences();
        return { success: true };
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
        return { success: false };
      }

    } catch (error) {
      console.error('Error uploading user experience:', error);

      if (error.response) {

        if (error.response.data.error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.error,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

        return { success: false };

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));

        return { success: false };

      }
    }
  }

  const handleExperienceDelete = async (data) => {

    console.log('handle experience delete clicked');

    try {

      const response = await axios.delete(`${network.serverip}/api/user-experience-delete-web/${data.id}`);

      if (response.data.success && isMountedRef.current) {
        console.log('User experience deleted successfully:', response.data);
        fetchUserExperiences();
        setFormData({
          id: '',
          jobTitle: '',
          institutionName: '',
          location: '',
          fromDate: '',
          toDate: '',
          currentlyWorkHere: false,
        });
        setModalVisible(false);
        setErrors({});
      } else {
        console.error('Failed to delete user experience:', response.data);
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
      }
    } catch (error) {
      console.error('Failed to delete user experience:');
      setErrors(prevErrors => ({
        ...prevErrors,
        uploadError: 'Something went wrong, please try again',
      }));
    }

  }

  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the user id');
      return;
    }

    isMountedRef.current = true;

    fetchUserExperiences();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId]);

  const styles = UserExperienceStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-10 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">

              <div className="col-10">
                <div className="bg-white rounded-4 shadow-9">

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => { }}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Experience
                      </h4>
                    </button>

                    <div>
                      {userExperiences && userExperiences.length > 0 ? (
                        userExperiences.map((exp, index) => {
                          const fromDate = exp.from_date ? new Date(exp.from_date) : null;
                          const toDate = exp.currently_work_here
                            ? 'Present'
                            : exp.to_date
                              ? new Date(exp.to_date)
                              : null;

                          const formattedFromDate = fromDate
                            ? fromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                            : 'Start date not available';
                          const formattedToDate =
                            toDate === 'Present'
                              ? 'Present'
                              : toDate
                                ? toDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                                : 'End date not available';

                          const monthsOfExperience =
                            fromDate && toDate !== 'Present' && toDate
                              ? Math.floor((toDate - fromDate) / (1000 * 60 * 60 * 24 * 30))
                              : fromDate && toDate === 'Present'
                                ? Math.floor((new Date() - fromDate) / (1000 * 60 * 60 * 24 * 30))
                                : null;

                          const yearsOfExperience = monthsOfExperience ? (monthsOfExperience / 12).toFixed(1) : null;

                          const experienceInYears = yearsOfExperience
                            ? `${yearsOfExperience} year(s)`
                            : 'Experience not available';

                          return (
                            <div className="w-100 border-top" key={index}>
                              <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                  <i className="fas fa-briefcase font-size-4 text-success"></i>
                                </div>
                                <div className="d-flex justify-content-between w-100">
                                  <div className="w-100">
                                    <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                      {exp?.job_title}
                                    </p>
                                    <p className="font-size-4 text-default-color line-height-2">
                                      {exp?.institution_name}
                                    </p>
                                    <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                      <span className="font-size-4 text-gray mr-5">
                                        {`${formattedFromDate} - ${formattedToDate} (${experienceInYears})`}
                                      </span>
                                      <span className="font-size-3 text-gray">
                                        <i className="fas fa-map-marker-alt mr-4"></i>
                                        {exp?.location}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-start">
                                    <button
                                      style={styles.editButton}
                                      onClick={() => { handleExperienceEdit(exp) }}
                                    >
                                      <i className="fas fa-pencil-alt text-success font-size-4"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No experience available.</p>
                      )}
                    </div>
                    <div className="w-100 pt-9 mb-9 border-top pr-11">
                      <button
                        className="w-100 d-flex align-items-center justify-content-between"
                        onClick={() => { handleExperienceCreate() }}
                        style={styles.addButton}
                      >
                        <p className="font-size-4 text-dodger font-weight-semibold mb-0">
                          {userExperiences.length > 0 ? 'Add another experience' : 'Add experience'}
                        </p>
                        <i className="fas fa-plus text-success font-size-4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
      <Modal
        size="lg"
        centered
        show={modalVisible}
      >
        <Modal.Body className="p-0">
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
            onClick={handleClose}
          >
            <i className="fas fa-times"></i>
          </button>
          <form onSubmit={handleSave} className="bg-white rounded-8 overflow-hidden">
            <div className="p-10 w-100">
              <div className="d-flex justify-content-center border-bottom border-mercury pb-2">
                <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                  {modalButtonGroup === 'createExperience'
                    ? 'Add Experience'
                    : 'Edit Experience'}
                </h4>
              </div>
              <div className="w-100 border-bottom border-mercury mb-5">

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="jobTitle"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Job Title:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter job title"
                    id="jobTitle"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onFocus={() => setIsFocused('jobTitle')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'jobTitle' ? styles.focussedInput : {}}
                  />
                  {errors?.jobTitle ? <p className="font-size-3 text-danger">
                    {errors?.jobTitle}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="institutionName"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Hospital Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter hospital name"
                    id="institutionName"
                    name="institutionName"
                    value={formData.institutionName}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onFocus={() => setIsFocused('institutionName')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'institutionName' ? styles.focussedInput : {}}
                  />
                  {errors?.institutionName ? <p className="font-size-3 text-danger">
                    {errors?.institutionName}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="location"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Location:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the location name"
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onFocus={() => setIsFocused('location')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'location' ? styles.focussedInput : {}}
                  />
                  {errors?.location ? <p className="font-size-3 text-danger">
                    {errors?.location}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="fromDate"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Select joining date:
                  </label>
                  <div className="w-100">
                    <DatePicker
                      selected={formData.fromDate}
                      onChange={(date) => {
                        const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
                        setFormData({ ...formData, fromDate: formattedDate });
                      }}
                      placeholderText="Select a date"
                      id="fromDate"
                      name="fromDate"
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      autoComplete="off"
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors?.fromDate ? <p className="font-size-3 text-danger">
                    {errors?.fromDate}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="toDate"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Select last working date:
                  </label>
                  <div className="w-100">
                    <DatePicker
                      selected={formData.toDate}
                      onChange={(date) => {

                        const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
                        setFormData({ ...formData, toDate: formattedDate, currentlyWorkHere: false });
                      }}
                      placeholderText="Select a date"
                      id="toDate"
                      name="toDate"
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      autoComplete="off"
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors?.toDate ? <p className="font-size-3 text-danger">
                    {errors?.toDate}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <input
                    type="checkbox"
                    id="currentlyWorkHere"
                    name="currentlyWorkHere"
                    checked={formData.currentlyWorkHere}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setFormData({
                        ...formData,
                        toDate: isChecked ? '' : formData.toDate,
                        currentlyWorkHere: isChecked,
                      });
                    }}
                  />
                  <label
                    htmlFor="currentlyWorkHere"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset ml-2"
                  >
                    {formData.currentlyWorkHere ? 'Currently work here' : 'Currently not working here'}
                  </label>
                </div>

              </div>

              <div className="w-100 d-flex justify-content-end">
                {modalButtonGroup === 'createExperience' ? (
                  <div className="w-100 d-flex flex-column flex-sm-row justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveAndAddAnother}
                      type="button"
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Save and add another
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="w-100 d-flex flex-column flex-sm-row justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => { handleExperienceDelete(formData) }}
                      className="btn btn-outline-danger h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Delete
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                )}
              </div>

              <div className="w-100 d-flex justify-content-center">
                {errors?.uploadError ? <p className="font-size-3 text-danger">
                  {errors?.uploadError}
                </p> : null}
              </div>

            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UserExperience;

function UserExperienceStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    addButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    editButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    suggestionContainer: {
      maxHeight: "200px",
      overflowY: "auto",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    suggestionItemStyle: {
      cursor: "pointer",
      backgroundColor: "white",
      border: "none",
      outline: 'none',
    },
    focussedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }
  };

  return styles;
}

